let cdEnded = true;

function toggleEvent(event) {
  const toggler = event.target.closest("[data-toggle-selector],[data-toggle-body],[data-toggle-class],[data-toggle-self]");
  if (!toggler) return;

  console.debug("Hello, I am", toggler);

  const { dataset } = toggler;

  if (dataset.toggleSelf) toggler.classList.toggle(dataset.toggleSelf);
  if (dataset.toggleBody) document.body.classList.toggle(dataset.toggleBody);
  if (!dataset.toggleSelector) return;

  const target = document.querySelector(dataset.toggleSelector);
  if (!target) return;

  console.debug("Target:", target);

  if (dataset.toggleClass) target.classList.toggle(dataset.toggleClass);
}

function setupTogglers() {
  document.body.addEventListener("click", toggleEvent);
}

function handleNewElements(mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
      mutation.addedNodes.forEach(newNode => {
        if (newNode.nodeType === 1) {
          setupTogglers();
        }
      });
    }
  }
}

const observer = new MutationObserver(handleNewElements);
const observerConfig = { childList: true, subtree: true };
observer.observe(document.body, observerConfig);

setupTogglers();
